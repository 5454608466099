@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Georgian:wght@400;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: sf-pro-rounded;
  font-display: auto;
  src: url("./assets/fonts/FontsFree-Net-SF-Pro-Rounded-Regular.woff");
}

@layer base {
  html,
  body {
    height: 100%;
  }
  #root {
    height: 100%;
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.swiper-wrapper {
  align-items: center;
}

.main-input {
  -webkit-text-fill-color: #3f2c65;
  opacity: 1; /* required on iOS */
}

.main-input::placeholder {
  -webkit-text-fill-color: #d9d8e5;
}

.swiper-slide {
  display: flex;
  justify-content: center;
  width: 120px;
}

.swiper-custom-bullet {
  height: 2px;
  width: 2px;
  background-color: white;
}

.swiper-custom-bullet.swiper-custom-bullet-active {
  height: 2px;
  width: 2px;
  background-color: orange;
}
#my-custom-pagination-div {
  margin: auto;
  text-align: center;
  margin-top: 24px;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

#my-custom-pagination-div.swiper-pagination-bullet {
  width: 11px;
  height: 11px;
}
#my-custom-pagination-div2.swiper-pagination-bullet {
  width: 11px;
  height: 11px;
}

.loader {
  margin: auto;
  width: 48px;
  height: 48px;
  border: 5px solid #170738;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.shadowLeft {
  /* border-radius: 32px;
  
  box-shadow: -50px 0px 0px 10px #f0efff; */

  position: relative;
}
.shadowLeft::after {
  background-color: #f0efff;
  z-index: -1;
  content: " ";
  height: 480px;
  position: absolute;
  left: -7%;
  right: 0;
  top: -10%;
  bottom: 0;
  width: 540px;
  border-radius: 32px;
}
.shadowRight {
  /* border-radius: 32px;
  box-shadow: 50px 0px 0px 20px #f0efff; */
  position: relative;
}

.shadowRight::after {
  background-color: #f0efff;
  z-index: -1;
  content: " ";
  height: 480px;
  position: absolute;
  left: 7%;
  right: 0;
  top: -10%;
  bottom: 0;
  width: 540px;
  border-radius: 32px;
}

.greenShadowLeft {
  /* border-radius: 32px;
  box-shadow: -50px 0px 0px 20px #f3f9eb; */
  position: relative;
}

.greenShadowLeft::after {
  background-color: #f3f9eb;
  content: "";
  z-index: -1;
  height: 480px;
  position: absolute;
  left: -7%;
  right: 0;
  top: -10%;
  bottom: 0;
  width: 540px;
  border-radius: 32px;
}

.greenShadowRight {
  /* border-radius: 32px;
  box-shadow: 50px 0px 0px 20px #f3f9eb;*/
  position: relative;
}

.greenShadowRight::after {
  background-color: #f3f9eb;
  content: "";
  z-index: -1;
  height: 480px;
  position: absolute;
  left: 7%;
  right: 0;
  top: -10%;
  bottom: 0;
  width: 540px;
  border-radius: 32px;
}

.spinWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.swiper-container {
  width: 480px;
}

@media screen and (min-width: 640px) {
  .swiper-container {
    width: 100%;
  }
}

@media screen and (min-width: 1050px) {
  .swiper-container {
    width: 100%;
  }
}

@media screen and (min-width: 768px) {
  .swiper-container {
    width: 100%;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
