.modal {
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  pointer-events: none;
}

.modal.enter-done {
  opacity: 1;
  pointer-events: visible;
}

.modal.exit {
  opacity: 0;
}

.modal-content {
  overflow-y: hidden;
  width: 100%;
  position: absolute;
  background-color: #fff;
  transition: all 0.3s ease-in-out;
}

.modal-content--center {
  width: 80%;
  border-radius: 8px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal-content--bottom {
  top: unset;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  bottom: 0;
  transform: translateY(200px);
}

.modal.enter-done .modal-content--bottom {
  bottom: 0;
  transform: translateY(0px);
}

.modal.exit .modal-content--bottom {
  bottom: 0;
  transform: translateY(200px);
}

.modal-body {
  padding-top: 20px;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}
