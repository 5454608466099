.customInput {
  position: relative;
}
.customInputError {
  position: relative;
}

.customInput > input {
  font-size: 16px;
  padding-top: 20px;
  padding-left: 20px;
  background: white;
  outline: none;
  transition: all 0.2s;
  height: 60px;
}

.customInputError > input {
  font-size: 16px;
  padding-top: 20px;
  padding-left: 20px;
  background: white;
  outline: none;
  border: red;
  transition: all 0.2s;
  height: 60px;
}

.customInput > label {
  color: #8c81a3;
  font-size: 16px;
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
  pointer-events: none;
  transition: all 0.2s;
}
.customInputError > label {
  color: #8c81a3;
  font-size: 16px;
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
  pointer-events: none;
  transition: all 0.2s;
}

.hasValue > input,
.customInput input:focus {
  background-color: white;
  border: 1px solid #6c63ff;
}

/* .hasValue > input,
.customInputError input:focus {
  background-color: white;
  border: 1px solid red;
} */

.customInput input:focus + label,
.hasValue > label {
  top: 15px;
  font-size: 11px;
}
.customInputError input:focus + label,
.hasValue > label {
  top: 15px;
  font-size: 11px;
}
