/* Example CSS */

/* Target the container of the tabs */
.rts___btn {
  border-radius: 16px;
  height: 50px;
  /* background-color: rgba(63, 44, 101, 0.2); */
  font-size: 20px;
}

.rts___tab___selected {
  background-color: #170738;
  color: #fff;
  border: none;
}

.rts___tab___selected_two {
  background-color: #6c63ff;
  color: #fff;
  border: none;
}

.rts___tab___selected_three {
  background-color: #8cc63f;
  color: #fff;
  border: none;
}
